
import {Component} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';
import AccountRepository from "@/api/repositories/AccountRepository";
import {maxLength} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import {namespace} from "vuex-class";
import {AUTH_STORE_NAME, AuthStoreGetters} from "@/store/auth.store";
import Account from '@/models/Account';

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    newEmail: {
      required,
      email,
      maxLength: maxLength(MaxLengthValidation.EMAIL)
    }
  }
})
export default class ChangeEmailComponent extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Getter(AuthStoreGetters.CURRENT_ACCOUNT)
  private account!: Account;

  /**
   * sets email to accounts email
   * @private
   */
  private created() {
    this.newEmail = this.account.email!;
  }

  /**
   * holds the information of the input field of the new email
   */
  private newEmail: string = '';

  /**
   * flag that indicates if the email hint should be shown
   */
  private showEmailHint = false;

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  /**
   * is called when the user clicks on the save button, validates the inputs and tries to change the password of the
   * user
   * @private
   */
  private async onChangeEmailClicked() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      // tries to change the password in the api
      this.isLoading = true;
      await AccountRepository.updateEmail(this.newEmail);

      // Show Success Message
      this.showEmailHint = true;
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CHANGE_EMAIL_SUCCESS');
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 409:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CHANGE_EMAIL_ALREADY_EXISTS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CHANGE_EMAIL_FAILURE');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }
}
